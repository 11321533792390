import React from "react";
import Layout from "../Layout";
import "./../../assets/css/noticias.css";
import Header from "../features/Header";

const PoliticaPrivacidadePage: React.FC = () => {
    return (
        <>
            <Header
                title="Política de Privacidade"
                description="Sua privacidade é extremamente importante para nós."
                image=""
                type="website"
                type_twitter="summary"
                url={"politica-de-privacidade"}
            />
            <Layout>
                <div className="container">
                    <div className="content-container">
                        <div className="news-container-interna">
                            <div className="news-interna-header">
                                <h1>{"Política de Privacidade"}</h1>
                                <h2> {"Informações acerca da nossa política"} </h2>
                            </div>
                            <div className="new-text">
                                <h1>Política de Privacidade da 89FM João Câmara</h1>
                                <p>
                                    Sua privacidade é extremamente importante para nós. A presente Política de Privacidade contém informações sobre como
                                    tratamos, total ou parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam nosso site. Ao
                                    utilizar o site da 89FM João Câmara, você entende e aceita como usaremos seus dados pessoais conforme descrito nesta
                                    política.
                                </p>

                                <h2>1. Coleta de Informações</h2>
                                <p>
                                    Nós coletamos informações principalmente para fornecer uma experiência melhorada em nosso site. As informações que coletamos
                                    incluem:
                                </p>
                                <ul>
                                    <li>
                                        <strong>Dados para cadastro:</strong> Coletamos seu endereço de e-mail quando você se inscreve para receber nossa
                                        newsletter. Essa é a única informação pessoal que coletamos diretamente em nosso site.
                                    </li>
                                </ul>

                                <h2>2. Uso das Informações</h2>
                                <p>Utilizamos as informações que coletamos exclusivamente para os seguintes propósitos:</p>
                                <ul>
                                    <li>
                                        <strong>Envio de Newsletter:</strong> Se você optar por se inscrever em nossa newsletter, usaremos seu endereço de
                                        e-mail para enviar atualizações periódicas sobre novos conteúdos e informações do site. O envio pode ser descontinuado
                                        por você a qualquer momento através de um link de desinscrição fornecido em cada e-mail.
                                    </li>
                                </ul>

                                <h2>3. Armazenamento e Segurança das Informações</h2>
                                <p>
                                    Empregamos medidas de segurança adequadas para proteger seus dados contra acesso não autorizado, alteração, divulgação ou
                                    destruição. Seus dados são mantidos apenas pelo tempo necessário para fornecer o serviço solicitado.
                                </p>

                                <h2>4. Compartilhamento de Informações</h2>
                                <p>
                                    Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para cumprir com obrigações legais ou
                                    proteger nossos direitos.
                                </p>

                                <h2>5. Direitos do Usuário</h2>
                                <p>
                                    Você tem o direito de solicitar acesso, correção ou exclusão de suas informações pessoais em nossa posse. Para fazer isso,
                                    entre em contato conosco através do nosso e-mail de suporte.
                                </p>

                                <h2>6. Alterações na Política de Privacidade</h2>
                                <p>
                                    Podemos alterar esta Política de Privacidade de tempos em tempos. Qualquer alteração será comunicada em nosso site e, se as
                                    alterações forem significativas, enviaremos uma notificação mais destacada.
                                </p>

                                <h2>7. Contato</h2>
                                <p>
                                    Se você tiver qualquer dúvida sobre esta Política de Privacidade ou sobre como tratamos suas informações pessoais, sinta-se
                                    à vontade para entrar em contato conosco.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default PoliticaPrivacidadePage;
