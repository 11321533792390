import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchSlides, fetchConfiguracoes } from "../../utils/dados";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

// Defina um tipo para as imagens
interface Image {
    id: number;
    imagem: string;
    imagem_mobile: string;
    titulo: string;
    link: string;
}

interface ARadioData {
    link_admin: string;
}

const SlideShow: React.FC = () => {
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();
    const [imagens, setImagens] = useState<Image[]>();
    const [currentImage, setCurrentImage] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false); // Estado de carregamento da imagem

    const goToPrevious = () => {
        if (imagens) {
            setCurrentImage((prevImage) => (prevImage === 0 ? imagens.length - 1 : prevImage - 1));
        }
    };

    const goToNext = () => {
        if (imagens) {
            setCurrentImage((prevImage) => (prevImage === imagens.length - 1 ? 0 : prevImage + 1));
        }
    };

    const pauseSlideShow = () => {
        setIsPaused(true);
    };

    const resumeSlideShow = () => {
        setIsPaused(false);
    };

    const chooseImageSource = () => {
        if (imagens && imagens.length > 0) {
            const isMobile = window.innerWidth <= 768;
            return isMobile
                ? `${configuracoes?.link_admin}/uploads/${imagens[currentImage].imagem_mobile}`
                : `${configuracoes?.link_admin}/uploads/${imagens[currentImage].imagem}`;
        }
        return "";
    };

    useEffect(() => {
        async function fetchData() {
            const imagens = await fetchSlides(4);
            setImagens(imagens);

            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (!isPaused && imagens) {
            interval = setInterval(goToNext, 5000);
        }

        return () => clearInterval(interval);
    }, [isPaused, imagens]);

    useEffect(() => {
        setImageLoaded(false); // Define como não carregada ao trocar de imagem
    }, [currentImage]);

    return (
        <div className="slideshow" onMouseEnter={pauseSlideShow} onMouseLeave={resumeSlideShow}>
            <div className="control control-icon-left" onClick={goToPrevious}>
                <FontAwesomeIcon icon={faChevronLeft} className="control-icon" />
            </div>
            {imagens && imagens.length > 0 && (
                <>
                    {!imageLoaded && (
                        <div className="loading-placeholder">
                            {/* Substitua isso por um spinner ou um estilo apropriado */}
                            <span>Carregando...</span>
                        </div>
                    )}
                    <img
                        className={`fade ${imageLoaded ? "visible" : "hidden"}`}
                        src={chooseImageSource()}
                        alt={imagens[currentImage]?.titulo}
                        onLoad={() => setImageLoaded(true)} // Define a imagem como carregada
                    />
                </>
            )}
            <div className="control control-icon-right" onClick={goToNext}>
                <FontAwesomeIcon icon={faChevronRight} className="control-icon" />
            </div>
        </div>
    );
};

export default SlideShow;
