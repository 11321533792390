import React, { useState, useEffect } from "react";
import { fetchConfiguracoes } from "../utils/dados";
import "./../assets/css/styles.css";
import "./../assets/css/footer.css";

interface ARadioData {
    titulo: string;
    descricao_resumida: string;
    link_admin: string;
    logo: string;
    googlemaps: any;
}

const Footer: React.FC = () => {
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();
    const [showCookieConsent, setShowCookieConsent] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }
        
        fetchData();
        checkCookieConsent();

        // Load and initialize socket.io script
        const script = document.createElement("script");
        script.src = "https://cdn.socket.io/4.5.4/socket.io.min.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const socket = io();
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const checkCookieConsent = () => {
        // Check if user's cookie preference exists
        const consent = localStorage.getItem("cookieConsent");
        setShowCookieConsent(consent === null); // Show banner only if no preference is set
    };

    const handleAcceptCookies = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setShowCookieConsent(false);
    };

    const handleRejectCookies = () => {
        localStorage.setItem("cookieConsent", "rejected");
        setShowCookieConsent(false);
    };

    return (
        <>
            <footer>
                <div className="container container-footer">
                    <div className="container-logo">
                        <img src={`./../assets/img/logo.png`} alt={`${configuracoes?.titulo}`} style={{ maxWidth: "150px" }} />
                        <h3 className="titulo_footer">{configuracoes?.titulo}</h3>
                        <span className="descricao_footer">{configuracoes?.descricao_resumida ? configuracoes?.descricao_resumida : ""}</span>
                    </div>
                    <div className="container-map">
                        <div className="map" dangerouslySetInnerHTML={{ __html: configuracoes?.googlemaps }} />
                    </div>
                </div>
            </footer>
            {showCookieConsent && (
                <div className="cookie-consent">
                    <p>Este site utiliza cookies para melhorar a experiência do usuário. Você aceita o uso de cookies?</p>
                    <button onClick={handleAcceptCookies}>Aceitar</button>
                    <button onClick={handleRejectCookies}>Recusar</button>
                </div>
            )}
        </>
    );
};

export default Footer;
